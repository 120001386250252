import axios from '@/axios'
export default{
    getAll(){
        return axios.get("admin/organization")
    },

    store(organization){
        return axios.post("admin/organization",organization)
    },

    update(organization){
        return axios.put("admin/organization/" + organization.id ,organization)
    },

    destroy(organization){
        return axios.delete("admin/organization/" + organization.id)
    },

}
